import React from 'react';
import ReactDOM from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { UserProvider } from './components/Context/UserContext.tsx';

import App from './App';
import theme from './theme';
import "./locale/locale.tsx";

const root = ReactDOM.createRoot(document.getElementById('root'));

// PayPal client ID (replace with your actual client ID)
const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;

root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <PayPalScriptProvider options={{ "client-id": PAYPAL_CLIENT_ID }}>
      <ChakraProvider theme={theme}>
        <UserProvider>
          <App />
        </UserProvider>
      </ChakraProvider>
    </PayPalScriptProvider>
  </GoogleOAuthProvider>
);
