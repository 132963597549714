import React, { useState, lazy, Suspense } from 'react';
import {
    Box, IconButton, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody,
    ModalFooter, Button, Flex, Text, Stat, StatLabel, StatNumber, StatHelpText,
    Input, Spinner, Icon
} from '@chakra-ui/react';
import { FaCheck, FaTimes } from "react-icons/fa";
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { TOKEN_PRICE } from "../Context/Constants.tsx"
import { useUserContext } from '../Context/UserContext.tsx';

// Dynamically import BuyTokens
const BuyTokens = lazy(() => import('../Payments/PaypalPayments.tsx'));

const TokensWizard = ({ isOpen, onClose }) => {
    const { t } = useTranslation();
    const { email, setTokens } = useUserContext();
    const [amount, setAmount] = useState<number>(1);
    const [processState, setProcessState] = useState("waiting");


    const API_URL = process.env.REACT_APP_API_URL;

    const handlePaymentSuccess = async (details: any) => {
        console.log(`Payment successful! Transaction ID: ${details.id}`);
        const token = localStorage.getItem('token');

        const response = await axios.post(
            `${API_URL}api/auth/tokens`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    email: email,
                    amount: amount,
                },
            }
        );

        setTokens(response.data.tokens);
        setProcessState("success");
    };

    const handlePaymentFailed = (err) => {
        setProcessState("failed");
    }

    const onProcessing = () => {
        setProcessState("processing");
    }

    const handleClose = () => {
        setProcessState("waiting");
        setAmount(1);
        onClose();
    }

    const increaseAmount = () => setAmount((prev) => Math.min(prev + 1, 999));
    const decreaseAmount = () => setAmount((prev) => Math.max(prev - 1, 0));

    return (
        <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{t("selectAmount")}</ModalHeader>
                <ModalBody>
                    {processState === "waiting" && (
                        <Flex direction="column" alignItems="center" gap={4}>
                            <Flex alignItems="center">
                                <IconButton
                                    aria-label="Decrease amount"
                                    icon={<MinusIcon />}
                                    onClick={decreaseAmount}
                                    size="sm"
                                />
                                <Box px={2}>
                                    <Input
                                        type="number"
                                        value={amount}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value === "") {
                                                setAmount(parseFloat(value));
                                            } else if (!isNaN(parseFloat(value)) && parseFloat(value) <= 999) {
                                                setAmount(parseFloat(value));
                                            }
                                        }}
                                        onBlur={() => {
                                            if (!amount || amount < 0) {
                                                setAmount(0);
                                            }
                                        }}
                                        min={0}
                                        max={999}
                                        textAlign="center"
                                        w="90px"
                                        size="xl"
                                        fontSize="40px"
                                        borderRadius="20px"
                                    />

                                </Box>
                                <IconButton
                                    aria-label="Increase amount"
                                    icon={<AddIcon />}
                                    onClick={increaseAmount}
                                    size="sm"
                                />
                            </Flex>
                            <Stat>
                                <StatLabel>{t("total")}</StatLabel>
                                <StatNumber>${((amount || 0) * TOKEN_PRICE).toFixed(2)}</StatNumber>
                                <StatHelpText>{t("singlePayment")}</StatHelpText>
                            </Stat>
                        </Flex>
                    )}
                    {(processState !== "success" && processState !== "failed") && (
                        <Suspense fallback={<Spinner
                            thickness="4px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="brand.700"
                            size="xl"
                        />}>
                            <Box
                                opacity={amount > 0 ? 1 : 0.5}
                                pointerEvents={amount > 0 ? "auto" : "none"}
                                mt={2}
                            >
                                <BuyTokens
                                    amount={amount}
                                    handleSuccess={handlePaymentSuccess}
                                    handleFail={handlePaymentFailed}
                                    onProcessing={onProcessing}
                                    onClose={onClose}
                                />
                            </Box>
                        </Suspense>
                    )}
                    {processState === "processing" && (
                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px solid gray.200" p={4}>
                            <Text mb={5}>{t("waitingPayment")}</Text>
                            <Spinner
                                thickness="4px"
                                speed="0.65s"
                                emptyColor="gray.200"
                                color="brand.700"
                                size="xl"
                            />
                        </Box>
                    )}
                    {processState === "success" && (
                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px solid gray.200" p={4}>
                            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" w="100px" h="100px"
                                borderRadius="full"
                                border="4px solid"
                                borderColor="green.500"
                            >
                                <Icon as={FaCheck} color="green.500" boxSize={10} />
                            </Box>
                            <Text>{t("paymentSuccessful")}</Text>
                        </Box>
                    )}
                    {processState === "failed" && (
                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px solid gray.200" p={4}>
                            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" w="100px" h="100px"
                                borderRadius="full"
                                border="4px solid"
                                borderColor="red.500"
                            >
                                <Icon as={FaTimes} color="red.500" boxSize={10} />
                            </Box>
                            <Text mt={2}>{t("paymentFailed")}</Text>
                        </Box>
                    )}
                </ModalBody>
                <ModalFooter>
                    {processState === "success" ? (
                        <Button onClick={() => handleClose()} colorScheme="teal" variant="outline" >
                            Close
                        </Button>
                    ) : (
                        <Button onClick={() => handleClose()} colorScheme="red" variant="outline" disabled={processState === "processing" ? true : false} >
                            Cancel
                        </Button>
                    )}
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default TokensWizard;
