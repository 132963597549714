import React from 'react';
import {
    Box, Badge, IconButton, useDisclosure, Flex, Text
} from '@chakra-ui/react';
import { useUserContext } from '../Context/UserContext.tsx';

import TokensWizard from '../Payments/TokensWizard.tsx';

const PlanBar = ({ type }) => {
    const { plan, tokens } = useUserContext();
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <Box width="100%" alignItems="center"
                justifyContent={type === "mobile" ? "space-between" : "center"}
                height={type === "mobile" ? "40px" : "60px"}
                bg={type === "mobile" ? "gray.100" : "brand.600"}
                display={type === "mobile" ? "flex" : "grid"}
                mb={type === "mobile" ? "0" : "10px"}
            >

                <Badge colorScheme="yellow" mx={3} textAlign="center">{plan}</Badge>
                <Box display="flex" alignItems="center" mx={1}>
                    <Flex py={1} px={2} borderRadius="10px" alignItems="center" bg={type === "mobile" ? "gray.200" : "gray.600"}>
                        <Box boxSize={5} mr={1} bg="yellow.400" borderRadius="full" display="flex" alignItems="center" justifyContent="center">
                            <span style={{ fontSize: '0.7em', color: 'black', fontWeight: "bold" }}>T</span>
                        </Box>
                        <Text>{tokens}</Text>
                    </Flex>

                    <IconButton size="xs" colorScheme="blue" icon={<span>+</span>} onClick={onOpen} aria-label="Add tokens" mx={1} borderRadius="full" boxSize={6} />
                </Box>
            </Box>

            {/* Modal for Payment */}
            <TokensWizard isOpen={isOpen} onClose={onClose} />
        </>
    );
};

export default PlanBar;
