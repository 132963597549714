import React, { useEffect } from 'react';
import { Box, Text, HStack, Select, Flex } from "@chakra-ui/react";
import { useTranslation } from 'react-i18next';

const Footer = ({ml}) => {
  const { t, i18n } = useTranslation();

  // Load language from localStorage or default to 'en'
  const defaultLanguage = localStorage.getItem('language') || 'en';

  // Set initial language on component mount
  useEffect(() => {
    i18n.changeLanguage(defaultLanguage); // Set initial language
  }, [defaultLanguage, i18n]); // Dependency array

  const handleLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedLanguage = e.target.value;
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem('language', selectedLanguage); // Save to localStorage
  };

  return (
    <Box as="footer" p={3} bg="brand.600" color="white" ml={ml}>
      <Flex justifyContent="space-between" alignItems="center">
        {/* Copyright Text */}
        <Text fontSize="sm">{t("copyright")}</Text>

        {/* Language Selector */}
        <HStack spacing={2}>
          <Select
            name="language"
            aria-label="language selector"
            onChange={handleLanguageChange}
            defaultValue={defaultLanguage} // Use loaded language
            size="sm"
            width="120px"
            bg="white"
            color="black"
            borderRadius="md"
          >
            <option value="en"> 🇺🇸 EN </option>
            <option value="es"> 🇪🇸 ES </option>
          </Select>
        </HStack>
      </Flex>
    </Box>
  );
};

export default Footer;
