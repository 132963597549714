import React from "react";
import { Box, VStack, Flex } from "@chakra-ui/react";

import { useUserContext } from '../Context/UserContext.tsx';

import Links from "./Links.tsx";
import ProfileAvatar from "./ProfileAvatar.tsx";
import PlanBar from "./PlanBar.tsx"

const DesktopNav = () => {
    const { userName, email } = useUserContext();

    return (
        <>
            <Box as="nav" p={4} bg="brand.600" color="white" width="170px" minHeight="100vh" position="fixed" display="flex" flexDirection="column">
                <VStack spacing={4} mt={8} flex="1">
                    <Links onClose={null} />
                </VStack>
                {userName !== "" && email !== "" && (
                    <PlanBar type="desktop" />
                )}
                <Flex justify="center" align="center" mt="auto">
                    <ProfileAvatar />
                </Flex>
            </Box>
        </>
    );
};

export default DesktopNav;
