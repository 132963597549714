import React from "react";
import {
    Flex, IconButton, useDisclosure, Drawer, DrawerBody, DrawerOverlay,
    DrawerContent, DrawerCloseButton, VStack, Text
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { useTranslation } from 'react-i18next';

import Links from "./Links.tsx";
import ProfileAvatar from "./ProfileAvatar.tsx";
import PlanBar from "./PlanBar.tsx";
import { useUserContext } from '../Context/UserContext.tsx';

const MobileNav = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { email, userName } = useUserContext();
    const { t } = useTranslation();

    return (
        <>
            <Flex as="nav" p={4} bg="brand.600" color="white" alignItems="center" justifyContent="space-between" w="100%">
                <IconButton icon={<HamburgerIcon />} aria-label="Open Menu" variant="outline" color="white" onClick={onOpen} />
                <Text fontSize="lg" fontWeight="bold" textAlign="center">
                    {t("name")}
                </Text>
                <ProfileAvatar />
            </Flex>

            {userName !== "" && email !== "" && (
                <PlanBar type="mobile"/>
            )}

            <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerBody>
                        <VStack spacing={4} mt={8}>
                            <Links onClose={onClose} />
                        </VStack>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
};

export default MobileNav;
