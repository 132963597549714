import React from "react";
import { Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';


const Links = ({ onClose }) => {
    const { t } = useTranslation();

    return (
        <>
            <Link to="/home" onClick={onClose}>
                <Text fontSize="lg">{t("home")}</Text>
            </Link>
            <Link to="/recipes" onClick={onClose}>
                <Text fontSize="lg">{t("recipes")}</Text>
            </Link>
            <Link to="/pricing" onClick={onClose}>
                <Text fontSize="lg">{t("pricing")}</Text>
            </Link>
            <Link to="/account" onClick={onClose}>
                <Text fontSize="lg">{t("myAccount")}</Text>
            </Link>
        </>
    );
};

export default Links;
