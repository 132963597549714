import React, { useState, useEffect, lazy, Suspense } from 'react';
import {
    Avatar, useDisclosure, Button, Modal, ModalBody, ModalCloseButton, ModalContent,
    ModalHeader, ModalOverlay, Menu, MenuButton, MenuList, MenuItem, Text, Box, Spinner
} from "@chakra-ui/react";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { useUserContext } from '../Context/UserContext.tsx';

const GoogleLogin = lazy(() => import('@react-oauth/google').then(module => ({ default: module.GoogleLogin })));

const LoginModal = ({ isOpen, onClose, handleLoginSuccess, handleLoginError, loading }) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{t("login")}{"/"}{t("register")}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box>
                        <Text fontSize="lg" mb={5} textColor="brand.800">
                            {t("best")}{" "}{t("name")}.
                        </Text>

                        {loading ? (
                            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" border="1px solid gray.200" p={4}>
                                <Text mb={5}>{t("logging_in")}</Text>
                                <Spinner
                                    thickness="4px"
                                    speed="0.65s"
                                    emptyColor="gray.200"
                                    color="brand.700"
                                    size="xl"
                                />
                            </Box>
                        ) : (
                            <>
                                <Suspense fallback={<Spinner
                                    thickness="4px"
                                    speed="0.65s"
                                    emptyColor="gray.200"
                                    color="brand.700"
                                    size="xl"
                                />}>
                                    <GoogleLogin onSuccess={handleLoginSuccess} onError={handleLoginError} />
                                </Suspense>
                                <Box mt={5}>
                                    <Text fontSize="xs">
                                        {t("proceeding")}{" "}
                                        <a href="/terms" target="_blank" rel="noopener noreferrer" onClick={onClose}>
                                            <Text as="span" color="blue.500" _hover={{ textDecoration: "underline" }} fontSize="xs">
                                                {t("terms_use")}
                                            </Text>
                                        </a>
                                        <span>{t("proceeding_confirm")}{" "}</span>
                                        <a href="/privacy" target="_blank" rel="noopener noreferrer" onClick={onClose}>
                                            <Text as="span" color="blue.500" _hover={{ textDecoration: "underline" }} fontSize="xs">
                                                {t("terms_privacy")}
                                            </Text>
                                        </a>.
                                    </Text>
                                </Box>
                            </>
                        )}
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

const ProfileAvatar = () => {
    const { t } = useTranslation();
    const { userName, login, logout, setUserName, setPlan } = useUserContext();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loading, setLoading] = useState(false);
    const loginURL = process.env.REACT_APP_GOOGLE_LOGIN_URL;

    // Check for an existing session on mount
    useEffect(() => {
        const storedToken = localStorage.getItem('token');
        const storedUserName = localStorage.getItem('userName');
        const storedPlan = localStorage.getItem('plan');

        if (storedToken && storedUserName) {
            setUserName(storedUserName);
            setPlan(storedPlan || 'Free');
            // Set Axios interceptor to include token in headers
            axios.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`;
        }
    }, [setUserName, setPlan]);

    const handleLoginSuccess = async (credentialResponse) => {
        const token = credentialResponse.credential;
        setLoading(true);

        try {
            const response = await axios.post(loginURL, { token });
            const { name, plan, tokens, email, token: jwtToken } = response.data;

            // Store user data and JWT token in localStorage
            login(name, jwtToken, tokens, plan, email);

            // Set Axios interceptor to include token in headers
            axios.defaults.headers.common['Authorization'] = `Bearer ${jwtToken}`;

            onClose();
        } catch (error) {
            console.error('Login failed', error);
        } finally {
            setLoading(false);
        }
    };

    const handleLoginError = () => {
        console.error('Login failed');
    };

    const handleLogout = () => {
        logout(); // Remove user data and token from localStorage

        // Remove Authorization header
        delete axios.defaults.headers.common['Authorization'];
    };

    return (
        <>
            <Menu>
                <MenuButton as={Button} variant="unstyled" onClick={userName ? undefined : onOpen} aria-label="login or account button">
                    {userName ? (
                        <Avatar size="sm" name={userName} bg="blue.700" aria-label="login" />
                    ) : (
                        <FontAwesomeIcon icon={faSignInAlt} size="lg" color="blue.700" aria-label="user-profile" />
                    )}
                </MenuButton>
                {userName && (
                    <MenuList bg="gray.200" color="white" borderRadius="md" boxShadow="md">
                        <>
                            <MenuItem
                                as={Link}
                                to="/account"
                                bg="gray.200"
                                color="black"
                                _hover={{ bg: "gray.700", color: "white" }}
                            >
                                {t("myAccount")}
                            </MenuItem>
                            <MenuItem
                                bg="gray.200"
                                color="black"
                                _hover={{ bg: "gray.700", color: "white" }}
                                onClick={handleLogout}
                            >
                                {t("logout")}
                            </MenuItem>
                        </>
                    </MenuList>
                )}
            </Menu>

            <LoginModal
                isOpen={isOpen}
                onClose={onClose}
                handleLoginSuccess={handleLoginSuccess}
                handleLoginError={handleLoginError}
                loading={loading}
            />
        </>
    );
};

export default ProfileAvatar;
