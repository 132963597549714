import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  theme: {
    tokens: {
      fonts: {
        heading: { value: `'Figtree', sans-serif` },
        body: { value: `'Figtree', sans-serif` },
      },
    },
  },
  colors: {
    brand: {
      50: "#d9f2e3",
      100: "#afd9bb",
      200: "#86c193",
      300: "#5da96c",
      400: "#359144",
      500: "#227a33",
      600: "#1a6028",
      700: "#13471e",
      800: "#0b2e13",
      900: "#02150a",
    },
    secondary: {
      50: "#e3f4e8",
      100: "#c0e3c8",
      200: "#9cd3a8",
      300: "#78c389",
      400: "#55b369",
      500: "#3d9a50",
      600: "#30783f",
      700: "#23572f",
      800: "#15351e",
      900: "#081a0e",
    },
    accent: {
      100: "#e5faf0",
      200: "#c0ebd5",
      300: "#9bdbbb",
      400: "#76cca0",
      500: "#4fbc86",
    },
  },
});

export default theme;