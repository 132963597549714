import React, { Suspense, lazy } from "react";
import { Box, useBreakpointValue, useToast } from "@chakra-ui/react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useUserContext } from '../Context/UserContext.tsx';

import Footer from "./Footer.tsx";
import MobileNav from "./MobileNav.tsx";
import DesktopNav from "./DesktopNav.tsx";

const Calculator = lazy(() => import("../Calculator/Calculator.tsx"));
const Recipes = lazy(() => import("../Recipes/Recipes.tsx"));
const Pricing = lazy(() => import("./Pricing.tsx"));
const Account = lazy(() => import("../Profile/Account.tsx"));
const Terms = lazy(() => import("../Misc/Terms.tsx"));
const Privacy = lazy(() => import("../Misc/Privacy.tsx"));

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { isAuthenticated } = useUserContext();
  const { t } = useTranslation();
  const toast = useToast();

  React.useEffect(() => {
    if (!isAuthenticated) {
      toast({
        description: t("protectedRouteError"),
        status: "info",
        duration: 4000,
        isClosable: true,
        position: "top"
      });
    }
  }, [isAuthenticated, t, toast]);

  if (!isAuthenticated) {
    return <Navigate to="/home" replace />;
  }

  return <>{children}</>;
};

const Main = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <Router>
      <Box minHeight="100vh" display="flex" flexDirection="column">
        {/* Navigation menu and top navigation bar */}
        {isMobile ? <MobileNav /> : <DesktopNav />}

        {/* Main Content Area */}
        <Box flex="1" p={1} overflow="auto" ml={isMobile ? 0 : "170px"}>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<Navigate to="/home" replace />} />
              <Route path="/home" element={<Calculator />} />
              <Route path="/recipes" element={<Recipes onCocktailClick={undefined} onClose={undefined} />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/account" element={
                <ProtectedRoute>
                  <Account />
                </ProtectedRoute>
              } />
              <Route path="/terms" element={<Terms />} />
              <Route path="/privacy" element={<Privacy />} />
            </Routes>
          </Suspense>
        </Box>

        {/* Footer with margin adjustment */}
        <Footer ml={isMobile ? 0 : "170px"} />
      </Box>
    </Router>
  );
};

export default Main;
