import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

interface UserContextType {
    userName: string;
    plan: string;
    tokens: number;
    email: string;
    isAuthenticated: boolean;
    login: (name: string, token: string, tokens: string, plan: string, email: string) => void;
    logout: () => void;
    setUserName: (name: string) => void;
    setPlan: (plan: string) => void;
    setTokens: (tokens: number) => void;
    setEmail: (email: string) => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);



export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [userName, setUserNameState] = useState<string>(localStorage.getItem('userName') || '');
    const [plan, setPlanState] = useState<string>(localStorage.getItem('plan') || 'Free');
    const [tokens, setTokensState] = useState<number>(parseInt(localStorage.getItem('tokens') || '0'));
    const [email, setEmailState] = useState<string>(localStorage.getItem('email') || '');
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(localStorage.getItem('isAuthenticated') === 'true');

    useEffect(() => {
        if (isAuthenticated) {
            refreshUserData();
        }
    }, [isAuthenticated]);

    const refreshUserData = async () => {
        const API_URL = process.env.REACT_APP_API_URL;
        try {
            const response = await axios.get(`${API_URL}api/auth/tokens`);

            setTokens(response.data.tokens);
            setPlan(response.data.plan);
        } catch (error) {
            console.log(error);
        }
    };

    const login = (name, jwtToken, tokens, plan, email) => {
        localStorage.setItem('userName', name);
        localStorage.setItem('token', jwtToken);
        localStorage.setItem('tokens', tokens);
        localStorage.setItem('plan', plan);
        localStorage.setItem('email', email);
        localStorage.setItem('isAuthenticated', "true");

        setUserNameState(name);
        setPlanState(plan);
        setTokensState(tokens);
        setEmailState(email);
        setIsAuthenticated(true);
    }
    const logout = () => {
        localStorage.removeItem('userName');
        localStorage.removeItem('token');
        localStorage.removeItem('tokens');
        localStorage.removeItem('plan');
        localStorage.removeItem('email');
        localStorage.removeItem('isAuthenticated');

        setUserNameState("");
        setPlanState("Free");
        setTokensState(0);
        setEmailState("");
        setIsAuthenticated(false);
    }

    const setUserName = (name: string) => {
        localStorage.setItem('userName', name);
        setUserNameState(name);
    };

    const setPlan = (plan: string) => {
        localStorage.setItem('plan', plan);
        setPlanState(plan);
    };

    const setTokens = (tokens: number) => {
        localStorage.setItem('tokens', tokens.toString());
        setTokensState(tokens);
    };

    const setEmail = (email: string) => {
        localStorage.setItem('email', email);
        setEmailState(email);
    };

    return (
        <UserContext.Provider value={{ userName, plan, tokens, email, isAuthenticated, login, logout, setUserName, setPlan, setTokens, setEmail }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUserContext = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUserContext must be used within a UserProvider');
    }
    return context;
};
