export const TOKEN_PRICE = 5;

export const MAX_COCKTAILS = {
    "free": 2,
    "standard": 5,
    "premium": 1000000
}

export const NEW_COCKTAIL = {
    "name": "newCocktail",
    "steps": ["",],
    "ingredients": [
        {
            "name": "White rum",
            "quantity": 2,
            "unit": "oz",
            "type": "liquor"
        }
    ],
    "image": "strawberry_daiquiri.webp",
    "quantity": 0
}